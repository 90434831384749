<script setup lang="ts">
import { abtestInit, setPlatformContext } from "@/utils/abtest";

setPlatformContext({
  browser: {
    window,
    navigator,
  },
});
await abtestInit();
</script>
